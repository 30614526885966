import Swiper from 'swiper/swiper-bundle'


const blogCarousel = document.querySelector('.blog-carousel');

if(blogCarousel) {
    let mySwiper = new Swiper('.blog-carousel', {
        slidesPerView: 1,
        spaceBetween: 24,
        autoplay:false,
        speed:1000,
        navigation: {
            nextEl: '.blog-carousel__arrow--next',
            prevEl: '.blog-carousel__arrow--prev',
        },

        breakpoints: {
            1024: {
                slidesPerView:3,
                spaceBetween: 40
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40
            }
        }
    })
}

const testimonialsSlider = document.querySelector('.testimonials__slider');
if (testimonialsSlider) {

    let mySwiper = new Swiper('.testimonials__slider', {
        autoHeight: true,
        slidesPerView: 1,
        spaceBetween: 24,

        navigation: {
            nextEl: '.testimonials__slider__arrow--next',
            prevEl: '.testimonials__slider__arrow--prev',
        },
        loop:true,
        speed: 1000,
        breakpoints: {
            // when window width is >= 768px
            768: {
              slidesPerView: 2,
              spaceBetween: 32
            }
          }
    });
}



var partnerSliderLength = jQuery('.partner-slider__slider .swiper-slide').length;
if (partnerSliderLength > 2) {
    let mySwiper = new Swiper('.partner-slider__slider', {
        slidesPerView: 1,
        spaceBetween: 0,
        navigation: {
            nextEl: '.partner-slider__button-next',
            prevEl: '.partner-slider__button-prev',
        },
    });
}


var logoSliderLength = jQuery('.logo-slider__slider .swiper-slide').length;

if (logoSliderLength > 5) {
    let mySwiper = new Swiper('.logo-slider__slider', {
        slidesPerView: 2,
        spaceBetween: 0,
        autoplay: true,
        breakpoints: {
            767: {
              slidesPerView: 5,
            },
          }
    });
}

var tilesSliderLength = jQuery('.about-gallery__slider .swiper-slide').length;
if (tilesSliderLength > 1) {
    let mySwiper = new Swiper('.about-gallery__slider', {
        slidesPerView: '1',
        spaceBetween: 48,
        navigation: {
            nextEl: '.about-gallery__button-next',
            prevEl: '.about-gallery__button-prev',
        },
    });
}
